import React from "react";
import { Link } from "gatsby";
import kebabCase from "lodash/kebabCase";
import { rhythm } from "../utils/typography";
import { GatsbyImage } from "gatsby-plugin-image";
import Date from "../components/date";

function PostList({ posts }) {
  return posts.map(({ node }) => {
    const title = node.frontmatter.title || node.fields.slug;
    return (
      <div key={node.fields.slug}>
        <h3
          style={{
            marginBottom: rhythm(1 / 4),
          }}
        >
          <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
            {title}
          </Link>
        </h3>

        <Date date={node.frontmatter.date} updated={node.frontmatter.updated} />

        {node.frontmatter.teaser && (
          <div>
            <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
              <GatsbyImage
                image={node.frontmatter.teaser.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={title}
              />
            </Link>
          </div>
        )}
        <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        <small>
          in{" "}
          {node.frontmatter.tags.map((tag) => (
            <>
              <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>{" "}
            </>
          ))}
        </small>
      </div>
    );
  });
}

export default PostList;
